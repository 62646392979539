<template>
  <div>
    <div
      v-if="isShow"
      :class="['sc-bar', getClass(scheduleDetail), isAppointment(scheduleDetail)]"
      :style="styleObject"
      :draggable="false"
      @dragstart="moveStart"
      @dragend="moveEnd"
      @click="$emit('click-event')"
    >
      <div
        @click="goToAction()"
        class="header"
        :style="{width: '8vw'}"
      >{{ scheduleDetail.actionFileNumber }}</div>
      <div class="text truncate" :style="styles.text">
        {{ scheduleDetail.subject }}
        <span v-if="getCurrentScopes.indexOf('st2.viewcalculatedtravel') !== -1">
          {{ formatMiles(scheduleDetail.assignment.distanceToMiles) }}
        </span>
      </div>
      <div class="text truncate" :style="styles.text">{{ scheduleDetail.actionType }}</div>
      <div class="text truncate" :style="styles.text">{{ dateFormat(scheduleDetail.caseDueDate) }}</div>
    </div>
  </div>
</template>

<script>

import SchedulesService from "@/views/schedule/assetsSchedules";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {mapGetters} from "vuex";

const ScheduleService = new SchedulesService();
export default {
  props: {
    rowIndex: Number,
    keyNo: Number,
    unitWidth: Number,
    borderWidth: Number,
    startText: String,
    endText: String,
    minDate: String,
    unit: Number,
    dragenterRowIndex: Number,
    dragenterColumnIndex: Number,
    isSelecting: Boolean,
    isSelectingRowIndex: Number,
    isSelectingIndex: Number,
    scheduleDetail: Object
  },

  data: function() {
    return {
      styleObject: {
        Opacity: 1,
        padding: "0.2rem",
        left: "0px",
        width: "0vw",
        height: "5rem",
        margin: "3px",
        top: "0"
      },
      isShow: false,
      mouseXStarted: null,
      startLineNo: null,
      endLineNo: null,
      isMe: false,
      isMove: false,
      styles: {
        text: {
          fontSize: "0.8rem",
          height: "auto",
          overflow: "auto",
          width: "8vw"
        }
      }
    };
  },

  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    })
  },

  watch: {
    startText(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.startDate = newVal;
        this.setLeftPosition();
      }
    },
    endText(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.endDate = newVal;
        this.setWidth();
        if (
            this.isSelecting &&
            this.mouseXStarted &&
            this.rowIndex === this.isSelectingRowIndex &&
            this.keyNo === this.isSelectingIndex
        ) {
          let diff = ScheduleService.getMinutesDiff(new Date(oldVal), new Date(newVal));
          let cnt = parseInt(diff / this.unit);
          this.mouseXStarted += this.unitWidth * cnt;
        }
      }
    },
  },

  created() {
    if (new Date(this.startText) - new Date(this.minDate) < 0 &&
        new Date(this.endText) - new Date(this.minDate) < 0) {
      return;
    }
    this.setLeftPosition();
    this.setWidth();
    this.isShow = true;
  },

  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    setLeftPosition() {
      let shiftCnt = this.getItemColumn() - 1;
      this.startLineNo = shiftCnt;
      let shiftLeft = this.unitWidth * shiftCnt * this.borderWidth;
      this.styleObject.left = shiftLeft + "vw";
    },

    setWidth() {
      let widthCnt = 1;
      this.endLineNo = this.startLineNo + widthCnt;
      let width = this.unitWidth * widthCnt * this.borderWidth;
      this.styleObject.width = (width - 0.4) + "vw";
    },

    moveStart(e) {
      if (this.isSelecting) {
        e.preventDefault();
        return;
      }
      this.styleObject.Opacity = 0.5;
      this.isMove = true;
      this.mouseXStarted = e.clientX;
      this.$emit("set-dragenter-row-and-index", this.rowIndex, this.getItemColumn());
    },

    moveEnd(e) {
      let mouseXEnd = e.clientX;
      if (this.isMove && (mouseXEnd !== this.mouseXStarted ||
          this.dragenterRowIndex !== this.rowIndex)) {
        this.mouseXStarted = null;
        const unitCnt = (this.dragenterColumnIndex - this.getItemColumn());
        if (this.dragenterColumnIndex !== null && (unitCnt !== 0 || this.dragenterRowIndex !== this.rowIndex)) {
          this.$emit("move-schedule-data", this.rowIndex, this.keyNo, unitCnt);
        }
      }
      this.isMove = false;
      this.styleObject.Opacity = 1;
    },

    getItemColumn() {
      let leftDiff = ScheduleService.getMinutesDiff(
          new Date(this.minDate),
          new Date(this.startText)
      );
      return parseInt(leftDiff / this.unit) + 1;
    },

    goToAction() {
      const url = '/cases/edit-action/' + this.scheduleDetail.actionID;
      window.open(url, '_blank')
    },

    dateFormat(val) {
      return ScheduleService.dateFormat(val)
    },

    getClass(val) {
      if (val.isClosed) {
        return "isClosed";
      } else if (val.isDouble) {
        return "isDouble";
      } else {
        return 'isMe'
      }
    },

    isAppointment(val) {
      if (val.actionType === "Surveillance (Appointment)" || val.actionType === "Surveillance - Two Investigators (Appointment)") return "appointment";
      return "";
    },

    formatMiles(value) {
      if (value && value > 1) {
        return " (" + Math.floor(value).toString() + ")";
      } else {
        return ""
      }
    }
  },
}
</script>

<style scoped>

</style>